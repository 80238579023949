import Typewriter from 'typewriter-effect'

const Bumper = () => {
    return <div className="Bumper" alt="Summoning great games since 2022">
        <Typewriter
            onInit={(typewriter) => {
                typewriter.typeString('SUMMONING GREAT GAMES SINCE 2022')
                    .start()
            }}
            options={{
                cursor: '_',
                skipAddStyles: false
            }}
        />
    </div>
}

export default Bumper