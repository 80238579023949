import './App.css'
import logo from './assets/chg_banner.png'
import Bumper from "./components/Bumper"

function App() {
  return (
    <div className="App">
      <div className="App-header"><img src={logo} className="App-logo" alt="Logo for Cosmic Horror Games." /></div>
      <Bumper />
    </div>
  )
}

export default App
